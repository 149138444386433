import React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/layout";
import type { TagTemplateQuery } from "../../types/graphql-types";
import { SEO } from "../components/seo";
import { PostList } from "../components/organisms/PostList";

type Props = {
  data: TagTemplateQuery;
  pageContext: { tag: "string" };
  location: Location;
};

const TagTemplate: React.FC<Props> = ({ data, location, pageContext }) => {
  const items = data.allMarkdownRemark.nodes;
  const tagName = pageContext.tag || "無いやんけ！！";
  return (
    <Layout location={location}>
      <SEO description={`${tagName}の記事一覧です。お楽しみください。`} />
      {items.length === 0 || typeof items === undefined ? (
        <>
          <p>このタグにはまだ記事がありません！ごめんね！</p>
        </>
      ) : (
        <>
          <h2>{tagName}の記事一覧</h2>
          <p>{data.allMarkdownRemark.totalCount}件</p>
          <PostList posts={items} />
        </>
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query TagTemplate($tag: String!) {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { tags: { in: [$tag] } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
  }
`;

export default TagTemplate;
